import React from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
// import Call from '../components/Call';
import emailIcon from '../../public/images/social/email-black.svg';

class HallOfFame extends React.Component {
  render() {
    const { posts } = this.props.data.blog;
    return (
      <Layout bodyClass='page-blog'>
        <SEO title="Responsible Disclosure Program" />
        <div className='container pb-6 pt-6 pt-md-10 pb-md-10'>
          <h1 className='title'>Responsible Disclosure Program</h1>
          <br />
          <div className='row justify-content-start'>
            <div className='col-12 col-md-8 col-lg-8'>
              <p>
                VNG is devoted to preserving the security of its clients by
                preventing unauthorized disclosure of personal information. This
                policy provides security researchers with clear parameters for
                performing vulnerability discovery operations and communicates
                our preferences in submitting found vulnerabilities.
              </p>
              <p>
                This policy explains which systems and types of research are
                covered, how to send us vulnerability reports, and how long
                security researchers must wait before revealing vulnerabilities
                publicly.
              </p>
              <p>
                As outlined in this policy, we want security researchers to feel
                comfortable reporting vulnerabilities they have uncovered so
                that we can address them and keep our users safe. This policy
                reflects our beliefs and demonstrates our feeling of duty toward
                security researchers who share their knowledge with us in good
                faith.
              </p>
              <br />
              <h3>Authorization</h3>
              <p>
                If you make a good faith effort to comply with this policy
                throughout your security research, we will consider it is
                authorized and VNG will not pursue legal action with your
                research.
              </p>
              <br />
              <h3>Systems in Scope</h3>
              <p>
                This policy applies to any digital assets owned, operated, or
                maintained by VNG.
              </p>
              <br />
              <h3>Out of Scope</h3>
              <p>
                <p>
                  Assets or other equipment not owned by parties participating
                  in this policy.
                </p>
                <p>
                  Vulnerabilities discovered or suspected in out-of-scope
                  systems should be reported to the appropriate vendor or
                  applicable authority.
                </p>
              </p>
              <br />
              <h3>Our Commitments</h3>
              <p>
                When working with us, according to this policy, you can expect
                us to:
              </p>
              <ul className='ul-rd'>
                <li>
                  <p>
                    Respond to your report promptly, and work with you to
                    understand and validate your report;
                  </p>
                </li>
                <li>
                  <p>
                    Strive to keep you informed about the progress of a
                    vulnerability as it is processed;
                  </p>
                </li>
                <li>
                  <p>
                    Work to remediate discovered vulnerabilities in a timely
                    manner, within our operational constraints; and
                  </p>
                </li>
                <li>
                  <p>
                    Extend Safe Harbor for your vulnerability research that is
                    related to this policy.
                  </p>
                </li>
              </ul>
              <br />
              <h3>Coordinated Disclosure</h3>
              <p>
                In participating in our vulnerability disclosure program in good
                faith, we ask that you:
              </p>
              <ul className='ul-rd'>
                <li>
                  <p>Report any vulnerability you have discovered promptly;</p>
                </li>
                <li>
                  <p>
                    Avoid violating the privacy of others, disrupting our
                    systems, destroying data, and/or harming user experience;
                  </p>
                </li>
                <li>
                  <p>
                    Use only the Official Channels to discuss vulnerability
                    information with us;
                  </p>
                </li>
                <li>
                  <p>
                    Provide us a reasonable amount of time (at least 90 days
                    from the initial report) to resolve the issue before you
                    disclose it publicly;
                  </p>
                </li>
                <li>
                  <p>
                    Perform testing only on in-scope systems, and respect
                    systems and activities which are out-of-scope;
                  </p>
                </li>
                <li>
                  <p>
                    If a vulnerability provides unintended access to data: Limit
                    the amount of data you access to the minimum required for
                    effectively demonstrating a Proof of Concept; and cease
                    testing and submit a report immediately if you encounter any
                    user data during testing, such as Personally Identifiable
                    Information (PII), Personal Healthcare Information (PHI),
                    credit card data, or proprietary information;
                  </p>
                </li>
                <li>
                  <p>
                    You should only interact with test accounts you own or with
                    explicit permission from the account holder; and
                  </p>
                </li>
                <li>
                  <p>Do not engage in extortion.</p>
                </li>
              </ul>
              <br />
              <h3>Official Channel</h3>
              <p>
                Please report security issues via vsrc@vng.com.vn, providing all
                relevant information. The more details you provide, the easier
                it will be for us to triage and fix the issue.
              </p>
              <br />
              <h3>Safe Harbor</h3>
              <p>
                When conducting vulnerability research, according to this
                policy, we consider this research conducted under this policy to
                be:
              </p>
              <ul className='ul-rd'>
                <li>
                  <p>
                    Authorized concerning under Vietnam Cybersecurity Law, and
                    we will not initiate or support legal action against you for
                    accidental, good-faith violations of this policy
                  </p>
                </li>
                <li>
                  <p>
                    Authorized concerning any relevant anti-circumvention laws,
                    and we will not bring a claim against you for circumvention
                    of technology controls;
                  </p>
                </li>
                <li>
                  <p>
                    Exempt from restrictions in our Terms of Service (TOS)
                    and/or Acceptable Usage Policy (AUP) that would interfere
                    with conducting security research, and we waive those
                    restrictions on a limited basis; and
                  </p>
                </li>
                <li>
                  <p>
                    Lawful, helpful to the overall security of the Internet, and
                    conducted in good faith.
                  </p>
                </li>
              </ul>
              <p>
                You are expected, as always, to comply with all applicable laws.
                If legal action is initiated by a third party against you and
                you have complied with this policy, we will take steps to make
                it known that your actions were conducted in compliance with
                this policy.
              </p>
              <p>
                If at any time you have concerns or are uncertain whether your
                security research is consistent with this policy, please submit
                a report through one of our Official Channels before going any
                further.
              </p>
              <p>
                Note that the Safe Harbor applies only to legal claims under the
                control of the organization participating in this policy, and
                that the policy does not bind independent third parties.
              </p>
            </div>
            <div className='col-12 col-md-4 col-lg-4 rd-tips-container'>
              <h2>Tips</h2>
              <hr className='rd-tips-hr' />
              <p className='rd-tips-title'>
                Read our policy carefully before submitting a report.
              </p>
              <p className='rd-tips-title'>
                Only VSRC should receive the report.
              </p>
              <a
                href='mailto:vsrc@vng.com.vn'
                className='rd-tips-email-container'
              >
                <img
                  className='rd-tips-email-icon'
                  alt='email-icon'
                  src={emailIcon}
                />
                <p className='rd-tips-email-text'>vsrc@vng.com.vn</p>
              </a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query VDPQuery {
    blog: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          author
        }
        excerpt
        id
        fields {
          slug
        }
      }
    }
  }
`;

export default HallOfFame;
